<template>
  <div>
<!--    <div class="logo">
      <img
        src="static/mobile/logo.webp"
        width="65"
        class="wp-post-image wp-image-19"
        alt="{{ getcompany }}"
        srcset="./static/mobile/logo.webp"
        sizes="(max-width: 65px) 100vw, 65px"
      />
      <h1>{{ jsonData.company }}</h1>
    </div> -->
    <nav>
      <div class="pclogo" style="align-items: center">
        <img
          style="width: 3.8rem; height: 3.8rem"
          src="static/mobile/logo.webp"
          width="65"
          class="wp-post-image wp-image-19"
          alt="{{ getcompany }}"
          srcset="static/mobile/logo.webp"
          sizes="(max-width: 65px) 100vw, 65px"
        />
      </div>
      <a href="">HOME</a>
      <!-- <a :href="`https://dinfo.me/onlineorder/#/home/menu/?restid=${getrestid}`">ORDER / MENU</a> -->
      <!-- <a :href="`https://dinfo.me/onlineorder/#/home/menu/?restid=${getrestid}`">CART</a> -->
    </nav>
    <router-view />
  </div>
</template>
<script>
import _service from "@/service"
export default {
    data() {
        return {
            jsonData: {}
        }
    },
    mounted() {
        _service.getbasedatainfo().then((res)=>{
            this.jsonData = res
            document.title = this.getcompany
            // console.log('aaaa==', this.jsonData)
        })

    },
    computed: {
        getrestid() {
            return this.jsonData.restid
        },
        getcompany() {
            return this.jsonData.company
        },
        getcompany2() {
            return this.jsonData.company2
        },
        gettel() {
            return this.jsonData.tel
        },
        getaddress() {
            return this.jsonData.address
        },
        getopenhours() {
            return this.jsonData && this.jsonData.openhours ? this.jsonData.openhours.split(';') : null
        },
        getsubclass1() {
            return this.jsonData.subclass1
        },
        getsubclass2() {
            return this.jsonData.subclass2
        },
        getsubclass3() {
            return this.jsonData.subclass3
        },
        getadvwords() {
            return this.jsonData.advwords
        }
    }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  background-color: var(--bgcolor-primary);
  display: flex;
  padding: 0 2.2rem;
  align-items: center;
  a {
    font-weight: bold;
    color: #fff;
    font-size: 1.5rem;
    padding: 1.15rem 0rem;
    text-decoration: none;
    margin: 0 0.8rem;
    &.router-link-exact-active {
      // color: #42b983;
      border-bottom: 3px solid #1d736c;
      border-radius: 0.2rem;
    }
  }
}
.logo {
  background-color: var(--bgcolor-primary);
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  padding: 10px;
  font-size: 1.5rem;
  font-family: Arial;
  font-weight: bold;
  h1 {
    margin: 0;
    font-size: 1.5rem;
  }
  img {
    margin-right: 10px;
    width: 2.2rem;
    height: 2.25rem;
  }
}

.pclogo {
  display: none;
}
@media screen and (min-width: 100px) {
  .logo {
    display: none;
  }
  .pclogo {
    display: flex;
  }
  a {
    &.router-link-exact-active {
      // color: #42b983;
      background: #1d736c;
      padding-left: 1.1rem;
      padding-right: 1.1rem;
    }
  }
}
</style>
