<template>
    <div class="home">
        <header>
            <div class="welcome" style="padding: 0">
                <!--        <div class="order">
          <a :href="`https://dinfo.me/onlineorder/#/home/menu/?restid=${getrestid}`">ORDER ONLINE</a>
        </div> -->
        
                
                <div>
                    <div class="orderxx">
                        <div class="order" @click="SelectSubShopPanelShowModal = true">ORDER ONLINE</div>
                        <SelectSubShopPanel :visible="SelectSubShopPanelShowModal" @click="SelectSubShopPanelShowModal = false">
                           <template #header>
                                <div class="headtitlegroup">
                                <div class="headtitle">Please select</div>
                                <div class="headclose" @click="SelectSubShopPanelShowModal = false">X</div>
                                </div>
                                <div class="line"></div>
                            </template>
                            <template #body>
                                <div class="restinfogroup">
                                <div class="restinfo">
                                    <div class="resturlgroup">
                                    <div class="resttitle"><a href="https://dinfo.me/onlineorder/#/home/menu/?restid=snowbowl">Snow Bowl</a></div>
                                    <div class="restweburl">
                                      <a href="https://dinfo.me/onlineorder/#/home/menu/?restid=snowbowl"><img src="static/mobile/nextstep.png" /></a>
                                    </div>
                                    </div>
                                    <div class="restaddress">2491 San RamonValley, ste1, San Ramon, CA, 94583</div>
                                    <div class="resttel">925-718-5220</div>
                                    
                                </div>
                                <div class="restinfo">
                                    <div class="resturlgroup">
                                    <div class="resttitle"><a href="https://dinfo.me/onlineorder/#/home/menu/?restid=mandroteahouse">Mandro Teahouse</a></div>
                                    <div class="restweburl">
                                      <a href="https://dinfo.me/onlineorder/#/home/menu/?restid=mandroteahouse"><img src="static/mobile/nextstep.png" /></a>
                                      
                                    </div>
                                    </div>
                                    <div class="restaddress">34956 NEWARK BLVD, NEWARK, CA, 94560</div>
                                    <div class="resttel">510-413-7588</div>
                                    
                                </div>

                                </div>
                            </template>
<!--                            <template #footer>
                                <button @click="SelectSubShopPanelShowModal = false">关闭</button>
                            </template> -->
                        </SelectSubShopPanel>
                    </div>
                </div>
                
                <picture style="width: 100%; height: 100%">
                    <source media="(min-width: 800px)"
                        srcset="static/mobile/banner.webp, static/mobile/banner.webp 2x" />
                    <source media="(min-width: 450px)"
                        srcset="static/mobile/banner.webp, static/mobile/banner.webp 2x" />
                    <img style="width: 100%" src="static/mobile/banner.webp" srcset="static/mobile/banner.webp 2x"
                        alt="" />
                </picture>
            </div>
            <div class="des">{{ getadvwords }}</div>
        </header>
        <div class="speciality section_container" v-if="getsubclass1">
            <div class="title">{{ getsubclass1 }}</div>
            <div class="content">
                <div class="item-card" v-for="item of getsubclasscnt1" :key="item">
                    <picture style="width: 100%">
                        <source media="(min-width: 800px)"
                            :srcset="`static/mobile/1-${item}.webp, static/mobile/1-${item}.webp 2x`" />
                        <source media="(min-width: 450px)"
                            :srcset="`static/mobile/1-${item}.webp, static/mobile/1-${item}.webp 2x`" />
                        <img style="width: 100%" :src="`static/mobile/1-${item}.webp`" alt=""
                            :srcset="`static/mobile/1-${item}.webp 2x`" />
                    </picture>
                </div>
            </div>
        </div>
        <div class="flavor section_container" v-if="getsubclass2">
            <div class="title">{{ getsubclass2 }}</div>
            <div class="content">
                <div class="item-card" v-for="item of getsubclasscnt2" :key="item">
                    <picture style="width: 100%">
                        <source media="(min-width: 800px)"
                            :srcset="`static/mobile/2-${item}.webp, static/mobile/2-${item}.webp 2x`" />
                        <source media="(min-width: 450px)"
                            :srcset="`static/mobile/2-${item}.webp, static/mobile/2-${item}.webp 2x`" />
                        <img style="width: 100%" :src="`static/mobile/mobile/2-${item}.webp`" alt=""
                            :srcset="`static/mobile/2-${item}.webp 2x`" />
                    </picture>
                </div>
            </div>
        </div>
        <div class="section3 section_container" v-if="getsubclass3">
            <div class="title">{{ getsubclass3 }}</div>
            <div class="content">
                <div class="item-card" v-for="item of getsubclasscnt3" :key="item">
                    <picture style="width: 100%">
                        <source media="(min-width: 800px)"
                            :srcset="`static/mobile/3-${item}.webp, static/mobile/3-${item}.webp 2x`" />
                        <source media="(min-width: 450px)"
                            :srcset="`static/mobile/3-${item}.webp, static/mobile/3-${item}.webp 2x`" />
                        <img style="width: 100%" :src="`static/mobile/3-${item}.webp`" alt=""
                            :srcset="`static/mobile/3-${item}.webp 2x`" />
                    </picture>
                </div>
            </div>
        </div>
        <footer>
            <div>
                <h3>Address &nbsp; Contact</h3>
                <div>{{ getaddress }}</div>
                <div>{{ gettel }}</div>
            </div>
            <div>
                <h3>Opening Hours</h3>
                <div v-for="(item, index) in getopenhours" v-bind:key="index">
                    {{ item }}
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import _service from "@/service"
    import SelectSubShopPanel from "@/components/SelectSubShopPanel.vue"
    export default {
        components: {
            SelectSubShopPanel
        },
        data() {
            return {
                SelectSubShopPanelShowModal: false,
                jsonData: {}
            }
        },
        mounted() {
            _service.getbasedatainfo().then((res) => {
                this.jsonData = res
                document.title = this.getcompany
                // console.log('aaaa==', this.jsonData)
            })
        },
        computed: {
            getrestid() {
                return this.jsonData.restid
            },
            getcompany() {
                return this.jsonData.company
            },
            getcompany2() {
                return this.jsonData.company2
            },
            gettel() {
                return this.jsonData.tel
            },
            getaddress() {
                return this.jsonData.address
            },
            getopenhours() {
                // debugger
                // console.log(this.jsonData)
                return this.jsonData && this.jsonData.openhours ? this.jsonData.openhours.split(';') : null
            },
            getsubclass1() {
                return this.jsonData.subclass1
            },
            getsubclass2() {
                return this.jsonData.subclass2
            },
            getsubclass3() {
                return this.jsonData.subclass3
            },
            getadvwords() {
                return this.jsonData.advwords
            },
            getsubclasscnt1() {
                return this.jsonData.subclasscnt1 ? this.jsonData.subclasscnt1 * 1 : 0
            },
            getsubclasscnt2() {
                return this.jsonData.subclasscnt2 ? this.jsonData.subclasscnt2 * 1 : 0
            },
            getsubclasscnt3() {
                return this.jsonData.subclasscnt3 ? this.jsonData.subclasscnt3 * 1 : 0
            }
        }
    }
</script>
<style scoped lang="scss">
    header {
        margin: 1.45rem 1.2rem;

        .welcome {
            background: #eaeaea;
            border-radius: 0.6rem;
            padding-top: 1.3rem;
            box-sizing: border-box;
            position: relative;

            h2 {
                font-size: 1.4rem;
                font-family: Arial;
                font-weight: bold;
                color: #1d736c;
                margin: 0;
                text-transform: uppercase;
            }

            .order {
                width: 11rem;
                height: 3rem;
                background: #1d736c;
                border-radius: 1.5rem;
                margin: 0 auto;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;

                top: 11rem;
                position: absolute;
                left: 50%;
                transform: translate(-50%);

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .img {
                position: absolute;
                top: 0.65rem;
                right: 1.15rem;
                width: 2.6rem;
                height: 2.6rem;
                background: #1d736c;
                border-radius: 1.1rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .des {
            height: 3rem;
            margin-top: 0.5rem;
            background: var(--bgcolor-primary);
            border-radius: 0rem 0rem 1rem 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 1.2rem;
            font-family: Arial;
            font-weight: bold;
            // text-transform: uppercase;
        }
    }

    .speciality {
        background-color: #ffe6da;
        color: #ff5101;

        .item-card {
            // border: 2px solid #ff5101;
        }
    }

    .flavor {
        background-color: #cbebfd;
        color: #0069a4;

        .item-card {
            // border: 2px solid #0069a4;
        }
    }

    .section3 {
        background-color: #eeeeee;
        color: #555555;

        .item-card {
            // border: 2px solid #555555;
        }
    }

    .section_container {
        padding: 0 1.2rem;
        margin-bottom: 1.4rem;

        .content {
            // display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            gap: calc((10vw - 0.7rem) / 2);
            text-align: left;
        }

        .title {
            font-size: 2rem;
            font-family: Arial;
            font-weight: bold;
            padding: 1.5rem 0;
            text-transform: uppercase;
        }

        .item-card {
            width: 30%;
            // background: #bdbdbd;
            overflow: hidden;
            // border-radius: 0.6rem;
            margin: 1rem 0rem;
            display: inline-block;

            // height: 15rem;
            img {
                width: 100%;
            }
        }
    }

    .section_container .item-card:nth-child(3n + 2) {
        margin-left: calc((10vw - 0.7rem) / 2);
        margin-right: calc((10vw - 0.7rem) / 2);
    }

    footer {
        background-color: var(--bgcolor-primary);
        color: #fff;
        padding: 1.5rem 0;
        line-height: 2;

        h3 {
            font-size: 1.5rem;
            font-family: Arial;
            font-weight: bold;
        }
    }

    @media screen and (min-width: 500px) {
        .home {
            // max-width: 1200px;
            // margin: auto;
        }

        header {
            margin: 0rem;

            .welcome {
                // height: 50rem;

                h2 {
                    font-size: 3rem;
                    margin-top: 2.9rem;
                }

                .order {
                    top: 78%;
                    width: 22rem;
                    height: 5.1rem;
                    border-radius: 2.55rem;
                    font-weight: bold;
                    font-size: 1.5rem;
                }

                .img {
                    width: 7rem;
                    height: 7rem;
                    border-radius: 3.5rem;
                    right: 5.3rem;
                    top: 2rem;
                }
            }

            .des {
                // height: 5.61rem;
                font-size: 3.5rem;
                margin: 1rem 0;
                padding: 2rem 0;
            }
        }

        .section_container {
            padding: 2.25rem;
            margin-bottom: 2.5rem;

            .content {}

            .title {
                font-size: 3.4rem;
                padding-top: 4.5rem;
            }

            .item-card {
                // border-radius: 1.5rem;
                height: auto;

                img {}
            }
        }

        footer {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 2rem;

            h3 {
                font-size: 2.4rem;
                margin-bottom: 1rem;
            }
        }
    }

    @media screen and (max-width: 300px) {
        .section_container .item-card {
            width: 45%;
        }

        .section_container .content {
            gap: calc(10% / 2);
        }
    }

    @media screen and (min-width: 1200px) {
        header .welcome .order[data-v-9ea40744] {
            top: 78%;
            width: 22rem;
            height: 7rem;
            font-weight: bold;
            font-size: 2.2rem;
            border-radius: 3.5rem;
        }
    }
    
    .headtitlegroup {
        display: flex;
    .headtitle {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      color: #000;
      // background-color: #ededed;
      margin-bottom: 10px;
    }
    .headclose {
        font-size: 32px;
        margin-left:auto;
        margin-right:10px;
    }
    }
    .line {
      margin-top: 1px;
      margin-bottom: 5px;
      width: 100%;
      border-top: 2px solid #ccc;
    }
    .restinfogroup {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        .restinfo {
            display: flex;
            flex-direction: column;
            padding-bottom: 25px;
            padding-top: 25px;
            .resturlgroup{
                display: flex;
                .resttitle{
                    font-size: 28px;
                    margin-left: 0px;
                    text-align: left;
                    font-weight: bold;
                    padding-bottom: 20px;
                }
                .restweburl{
                    font-size: 32px;
                    margin-left:auto;
                    margin-right:10px;
                }
            }
            .restaddress {
                font-size: 20px;
                margin-left: 0px;
                text-align: left;
            }
            
            .resttel {
                font-size: 20px;
                margin-left:0px;
                text-align: left;
            }
            
        }
        
    }

    
</style>