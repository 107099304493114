<template>
    <div class="modal-mask" v-show="visible">
        <div class="modal-container">
            <div class="modal-header">
                <slot name="header">
                    默认标题
                </slot>
            </div>
            <div class="modal-body">
                <slot name="body">
                    默认内容
                </slot>
            </div>
<!--            <div class="modal-footer">
                <slot name="footer">
                    <button @click="close">关闭</button>
                </slot>
            </div> -->
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            close() {
                this.$emit('update:visible', false);
            }
        }
    };
</script>
<style scoped>
    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-container {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        width: 85%;
        max-width: 1000px;
        color: black;
    }
</style>